@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}
